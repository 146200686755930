<template>
	
</template>

<script>
export default {
	data() {
		return {
			cards: [

			]
		}
	},
	mounted() {
		if(this.$store.state.auth.isSignedIn) {
			this.$router.push("/wishes");
		}
	}
};
</script>
